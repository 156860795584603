import striptags from 'striptags';

function _capitalize(string: string, index?: number) {
	let editedString = string.toLowerCase();

	if (index !== 0) {
		editedString = editedString.charAt(0).toUpperCase() + editedString.slice(1);
	}

	return editedString;
}

export function capitalize(string = '', separator) {
	return string.split(separator).reduce((left, curr, index) => {
		return left + _capitalize(curr);
	}, '');
}

export function camelize(string = '', separator) {
	return string.split(separator).reduce((left, curr, index) => {
		return left + _capitalize(curr, index);
	}, '');
}

export function ellipsis(string = '', length = 90, tail = '...', sanitize = true) {
	const sanitizedString = sanitize ? striptags(string) : string;

	return sanitizedString && sanitizedString.length > length
		? sanitizedString.substring(0, length - 3) + tail
		: sanitizedString;
}

export function linkify(string = '') {
	return string.replace(/(https?[^\s]+)/g, (all, href) => {
		return `<a href="${href}" target="_blank">${href}</a>`;
	});
}

export function camelToSnake(string = '') {
	const buffer = [];
	let currWord = '';

	for (let index = 0; index < string.length; index++) {
		const char = string.charAt(index);

		if (char === char.toUpperCase()) {
			buffer.push(currWord);
			currWord = '';
		}

		currWord += char.toLowerCase();
	}

	if (currWord) {
		buffer.push(currWord);
	}

	return buffer.join('_');
}

export function externalizeLinks(content = '') {
	if (!content) {
		return content;
	}

	return content.replace(/<a href/g, '<a target="_blank" href');
}

export function balanceHtmlTags(content = '') {
	const isServer = typeof window === 'undefined';

	if (isServer) {
		return content;
	}
	const elem = document.createElement('div');
	elem.innerHTML = content;

	return elem.innerHTML;
}

export function escapeSymbols(content = '') {
	if (!content) {
		return content;
	}

	return content.replace(/&.+?;/g, '');
}

export function reactHtmlSafe(content = '') {
	return {
		__html: externalizeLinks(content),
	};
}
