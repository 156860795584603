import React, { Component } from 'react';
import classNames from 'classnames';
import styles from './Author.module.scss';

interface IAuthorProps {
	initials?: string;
	label: string;
	locked?: boolean;
	invert?: boolean;
	big?: boolean;
}

export default class Author extends Component<IAuthorProps> {
	render() {
		const { initials, label, locked, big, invert } = this.props;

		const authorClassNames = classNames(styles.author, {
			[styles.authorBig]: big,
		});

		const badgeClassNames = classNames(styles.authorBadge, {
			[styles.authorBadgeInvert]: invert,
			[styles.authorBadgeBig]: big,
			[styles.authorBadgeLocked]: locked && !invert,
		});

		const labelClassNames = classNames(styles.authorLabel, {
			[styles.authorLabelLocked]: locked && !invert,
			[styles.authorLabelInvert]: invert,
			[styles.authorLabelBig]: big,
		});

		return (
			<div className={authorClassNames}>
				<div className={badgeClassNames}>{initials}</div>
				<div className={labelClassNames}>{label}</div>
			</div>
		);
	}
}
