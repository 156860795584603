import { CollectionDocument } from '@/interfaces/Jsonapi';
import { DevourCollectionResponse, Links, Meta, Resource } from 'devour-client';

/**
 * This is a fix for Devour incorrect handling of collection responses.
 * Devour is polluting native Array prototype and adding a `meta` and `links` property to it.
 * Next.js will not serialize those properties and we will lose them on the client side.
 *
 * You should always use this function to serialize collection responses.
 */
export const serializeCollectionResource = <
	TResource extends Resource = Resource,
	TMeta extends Meta = Meta,
	TLinks extends Links = Links
>(
	response: DevourCollectionResponse<TResource, TMeta, TLinks>
): CollectionDocument<TResource, TMeta, TLinks> => {
	return {
		data: [...response],
		...(response.meta && { meta: response.meta }),
		...(response.links && { links: response.links }),
	};
};
