import { useClient } from '@/components/shared/utilities/AppProvider/AppProvider';
import { FaveableResource } from '@/interfaces/IFavoriteResource';
import isRetina from '@/utils/image';
import { normalizeType } from '@/utils/type-util';
import classNames from 'classnames';
import { FC, useEffect, useState } from 'react';

import styles from './LikeButton.module.scss';

//icons

const FaveState = {
	UNFAVED: 0,
	FAVING: 1,
	FAVED: 2,
	UNFAVING: 3,
};

const animationEndDelay = 800;

const IconDefaultSrc = {
	[FaveState.UNFAVED]: {
		basic: '/assets/icons/whole-heart.gif',
		retina: '/assets/icons/whole-heart@2x.gif',
	},
	[FaveState.FAVING]: {
		basic: '/assets/icons/heart-breaking.gif',
		retina: '/assets/icons/heart-breaking@2x.gif',
	},
	[FaveState.FAVED]: {
		basic: '/assets/icons/broken-heart.gif',
		retina: '/assets/icons/broken-heart@2x.gif',
	},
	[FaveState.UNFAVING]: {
		basic: '/assets/icons/heart-unbreaking.gif',
		retina: '/assets/icons/heart-unbreaking@2x.gif',
	},
};

const IconInvertedSrc = {
	[FaveState.UNFAVED]: {
		basic: '/assets/icons/whole-heart-inverted.gif',
		retina: '/assets/icons/whole-heart-inverted@2x.gif',
	},
	[FaveState.FAVING]: {
		basic: '/assets/icons/heart-breaking-inverted.gif',
		retina: '/assets/icons/heart-breaking-inverted@2x.gif',
	},
	[FaveState.FAVED]: {
		basic: '/assets/icons/broken-heart-inverted.gif',
		retina: '/assets/icons/broken-heart-inverted@2x.gif',
	},
	[FaveState.UNFAVING]: {
		basic: '/assets/icons/heart-unbreaking-inverted.gif',
		retina: '/assets/icons/heart-unbreaking-inverted@2x.gif',
	},
};

interface ILikeButtonProps {
	className?: string;
	faveable: FaveableResource;
	inverted?: boolean;
}

const formatDataType = (data: FaveableResource) => {
	return {
		...data,
		type: normalizeType(data.type),
	};
};

export const LikeButton: FC<ILikeButtonProps> = ({ className, inverted, faveable }) => {
	const client = useClient();
	const [iconSrc, setIconSrc] = useState(() => (inverted ? IconInvertedSrc : IconDefaultSrc));
	const [faveState, setFaveState] = useState(() => (faveable.favoriter !== null ? FaveState.FAVED : FaveState.UNFAVED));
	const [count, setCount] = useState(() => faveable.favoritesCount);
	const [item, setItem] = useState(() => faveable);

	const likeButtonClassNames = classNames(styles.likeButton, className, {
		[styles.likeButtonInverted]: inverted,
	});

	const onFavoriteClick = async (client, item, event) => {
		event.stopPropagation();

		const formatedFaveable = formatDataType(item);

		if (item.favoriter !== null) {
			const favoriter = await client.destroy('user/favorite', item.favoriter.id);

			setFaveState(FaveState.UNFAVING);
			setCount(item.favoritesCount - 1);
			setItem((prev) => {
				return {
					...prev,
					favoritesCount: item.favoritesCount - 1,
					favoriter: favoriter,
				};
			});
		} else {
			const favoriter = await client.create('user/favorite', {
				faved: formatedFaveable,
			});

			setFaveState(FaveState.FAVING);
			setCount(item.favoritesCount + 1);
			setItem((prev) => {
				return {
					...prev,
					favoritesCount: item.favoritesCount + 1,
					favoriter: favoriter,
				};
			});
		}
	};

	useEffect(() => {
		if (faveable.favoriter !== null) {
			if (item.favoriter !== null) {
				if (item.favoriter.id !== item.favoriter.id) {
					setItem(faveable);
				}
			} else {
				setFaveState(FaveState.FAVING);
				setCount(faveable.favoritesCount);
				setItem(faveable);
			}
		} else {
			if (item.favoriter !== null) {
				setFaveState(FaveState.UNFAVING);
				setCount(faveable.favoritesCount);
				setItem(faveable);
			}
		}
	}, [faveable]);

	const getIcon = () => {
		const srcset = iconSrc[faveState];
		const src = isRetina() ? srcset.retina : srcset.basic;

		return <img src={src} className={styles.likeButtonIcon} />;
	};

	return (
		<div className={likeButtonClassNames} onClick={(event) => onFavoriteClick(client, item, event)}>
			{getIcon()}
			{count}
		</div>
	);
};
