import React, { FC } from 'react';
import classNames from 'classnames';
import styles from './SocialIcon.module.scss';

interface ISocialIconProps {
	onClick: (event: any) => void;
	inverse?: boolean;
	className?: string;
}

const SocialIcon: FC<ISocialIconProps> = (props) => {
	const { inverse, onClick, className } = props;

	const iconClassName = classNames(className, styles.socialIcon);

	const outlineClassName = classNames(styles.socialIconOutline, {
		[styles.socialIconOutlineInverse]: inverse,
	});

	return (
		<div onClick={onClick} className={iconClassName}>
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 18">
				<path fill="none" d="M-5-5h28v28H-5z" />
				<path
					className={outlineClassName}
					d="M14 12c-1 0-1.9.5-2.4 1.3l-5.8-3.1C6 9.8 6 9.4 6 9s-.1-.8-.2-1.2l5.8-3.1C12.1 5.5 13 6 14 6c1.7 0 3-1.3 3-3s-1.3-3-3-3-3 1.3-3 3v.3L4.8 6.6C4.3 6.2 3.7 6 3 6 1.3 6 0 7.3 0 9s1.3 3 3 3c.7 0 1.3-.2 1.8-.6l6.2 3.3v.3c0 1.7 1.3 3 3 3s3-1.3 3-3-1.3-3-3-3zm0-10.5c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.5 1.5-1.5-.7-1.5-1.5.7-1.5 1.5-1.5zm-11 9c-.8 0-1.5-.7-1.5-1.5S2.2 7.5 3 7.5s1.5.7 1.5 1.5-.7 1.5-1.5 1.5zm11 6c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5 1.5.7 1.5 1.5-.7 1.5-1.5 1.5z"
				/>
			</svg>
		</div>
	);
};

export default SocialIcon;
