import { flatten, last } from 'lodash';

export function extractSWRInfiniteData(data: Array<any>, error: any, size: number) {
	const isLoadingInitialData = !data && !error;
	const isLoadingMore = isLoadingInitialData || (size > 0 && data && !data[size - 1]);

	const isEmpty = data?.length === 0 || data?.[0]?.data?.length === 0;

	const isReachingEnd = isEmpty || !data?.[data.length - 1]?.links?.next;

	const items = flatten(
		data?.map((page) => {
			return page.data;
		})
	);

	const meta = last(data)?.meta;

	return {
		isLoadingMore,
		isReachingEnd,
		isEmpty,
		items,
		meta,
	};
}
