import { APP } from '@/utils/constants';
import striptags from 'striptags';

function objectToGetParams(object, { encode = true } = {}) {
	return (
		'?' +
		Object.keys(object)
			.filter((key) => Boolean(object[key]))
			.map((key) => `${key}=${encode ? encodeURIComponent(object[key]) : object[key]}`)
			.join('&')
	);
}

function getAbsoluteUrl(url) {
	// Maybe procces env here?
	return `https://brokenships.com/${url}`;
}

function constructBody(body, url) {
	const link = getAbsoluteUrl(url);

	return striptags(`${body || APP.description}\n\nSee more at: ${link}`);
}

export function email(subject, body, url) {
	return (
		'mailto:' +
		objectToGetParams({
			subject: subject || APP.title,
			body: constructBody(body, url),
		})
	);
}

export function twitter(url: string, text?: string, via?: string, hashtags: Array<string> = []) {
	return (
		'https://twitter.com/share' +
		objectToGetParams({
			url: getAbsoluteUrl(url),
			text: text || APP.description,
			via: via || 'Brokenships',
			hashtags: hashtags.join(','),
		})
	);
}

export function facebook(url) {
	return 'https://facebook.com/sharer.php' + objectToGetParams({ u: getAbsoluteUrl(url) });
}

export function getCollection(url, data) {
	return [
		{
			url: facebook(url),
			title: 'Share on Facebook',
		},
		{
			url: twitter(url),
			title: 'Share on Twitter',
		},
		{
			url: email(data.title, data.content, url),
			title: 'Share with email',
			target: '_top',
		},
	];
}
