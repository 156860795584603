export const size = {
	SMALL: 'small',
	MEDIUM: 'medium',
	LARGE: 'large',
	ORIGINAL: 'original',
	PRELOADER: 'preloader',
	FACEBOOK: 'facebook',
	TWITTER: 'twitter',
};

export function getDefaultImageUrl(attachmentSize) {
	switch (attachmentSize) {
		case size.FACEBOOK:
			return '/images/facebook-share-default.png';
		case size.TWITTER:
			return '/images/twitter-share-default.png';
		case size.ORIGINAL:
		case size.SMALL:
		case size.MEDIUM:
		case size.LARGE:
		case size.PRELOADER:
		default:
			return '/images/fallback-post-image-square-bg.svg';
	}
}

export function getImageUrl(attachment, attachmentSize, attachmentSizeFallback = size.LARGE) {
	if (attachment && attachment[attachmentSize]) {
		return attachment[attachmentSize].url;
	} else if (attachment && attachment[attachmentSizeFallback]) {
		return attachment[attachmentSizeFallback].url;
	}

	return getDefaultImageUrl(attachmentSize);
}

export function isUsingFallback(attachment, attachmentSize, attachmentSizeFallback = size.LARGE) {
	return attachment && (attachment[attachmentSize] || attachment[attachmentSizeFallback]) ? false : true;
}
