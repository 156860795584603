import React, { Component } from 'react';
import classNames from 'classnames';
import styles from './SocialBarMenu.module.scss';

interface ISocialBarMenuProps {
	data?: Array<any>;
	closeHandler?: (event: any) => void;
	collection: Array<any>;
	noSkew?: boolean;
	noBackdrop?: boolean;
	className?: string;
	containerClassName?: string;
	closeClassName?: string;
}

export default class SocialBarMenu extends Component<ISocialBarMenuProps> {
	onClickGuard(event: any) {
		event.stopPropagation();
	}

	render() {
		const { noSkew, noBackdrop } = this.props;

		const menuClassNames = classNames(styles.menu, this.props.className);

		const backdropClassNames = classNames(styles.menuBackdrop, {
			[styles.menuBackdropTopSkew]: noSkew,
		});

		const containerClassNames = classNames(
			styles.menuContainer,
			{
				[styles.menuContainerNoSkew]: noSkew,
			},
			this.props.containerClassName
		);

		const closeClassNames = classNames(styles.menuClose, this.props.closeClassName);

		return (
			<div className={menuClassNames} onClick={this.onClickGuard}>
				{noBackdrop ? null : <div className={backdropClassNames}></div>}
				<div className={containerClassNames}>
					<div className={styles.menuList}>
						{this.props.collection.map((item, index) => {
							return (
								<a
									key={index}
									className={styles.menuItem}
									href={item.url}
									target={item.target ? item.target : '_blank'}
								>
									{item.title}
								</a>
							);
						})}
					</div>
				</div>
				<div onClick={this.props.closeHandler} className={closeClassNames} />
			</div>
		);
	}
}
