import React, { Component } from 'react';
import classNames from 'classnames';
import styles from './ImageLoader.module.scss';

const Status = {
	LOADING: 'loading',
	LOADED: 'loaded',
	FAILED: 'failed',
};

interface IImageLoaderProps {
	src?: string;
	preloader?: string;
	className?: string;
	imageClassName?: string;
	onLoad?: (event: Event) => void;
	onError?: (event: Event) => void;
}

export default class ImageLoader extends Component<IImageLoaderProps> {
	state = {
		status: Status.LOADING,
	};

	img: HTMLImageElement;

	constructor(props: IImageLoaderProps) {
		super(props);

		this.state = {
			status: Status.LOADING,
		};
	}

	componentDidMount() {
		if (this.state.status === Status.LOADING) {
			this.createLoader();
		}
	}

	componentWillUnmount() {
		this.destroyLoader();
	}

	createLoader() {
		this.img = new Image();
		this.img.onload = this.handleLoad.bind(this);
		this.img.onerror = this.handleError.bind(this);
		this.img.src = this.props.src;
	}

	destroyLoader() {
		if (this.img) {
			this.img.onload = null;
			this.img.onerror = null;
			this.img = null;
		}
	}

	handleLoad(event) {
		this.destroyLoader();
		this.setState({ status: Status.LOADED });

		if (this.props.onLoad) {
			this.props.onLoad(event);
		}
	}

	handleError(error) {
		this.destroyLoader();
		this.setState({ status: Status.FAILED });

		if (this.props.onError) {
			this.props.onError(error);
		}
	}

	render() {
		const { src, preloader, className, imageClassName } = this.props;

		const wrapperClassNames = classNames(styles.wrapper, className);

		const imageClassNames = classNames(styles.image, imageClassName);

		return (
			<span className={wrapperClassNames}>
				{this.state.status === Status.LOADING ? (
					<div className={styles.preloader}>
						<img className={styles.preloaderImage} src={preloader} />
						<div className={styles.preloaderSpinner}>
							<div className={styles.preloaderSpinnerInner}></div>
						</div>
					</div>
				) : (
					<img className={imageClassNames} src={src} />
				)}
			</span>
		);
	}
}
