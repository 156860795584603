import moment from 'moment';

export function formatRelevantDate(
	relevantFrom,
	relevantFromFormat,
	relevantUntil,
	relevantUntilFormst,
	fallbackMessage
) {
	let relevant;

	if (relevantFrom) {
		relevant = moment(relevantFrom).format(relevantFromFormat);

		if (relevantUntil) {
			relevant += ' - ' + moment(relevantUntil).format(relevantUntilFormst);
		}
	} else {
		relevant = fallbackMessage;
	}

	return relevant;
}

export function getRelevantYears(relevantFrom, relevantUntil) {
	return this.formatRelevantDate(relevantFrom, 'YYYY', relevantUntil, 'YYYY', 'Seems like yesterday...');
}

export function getDateFilled(dateFilled) {
	return dateFilled || '';
}

export function getRelevantDate(relevantFrom, relevantUntil) {
	return this.formatRelevantDate(relevantFrom, 'MM/DD', relevantUntil, 'MM/DD-YYYY', '-');
}

export function formatPostedAtDate(postedAt) {
	return moment(postedAt).format('MMM DD, YYYY');
}

export function formatUnlocksAt(unlocksAt) {
	return moment(unlocksAt).format('DD.M.YYYY');
}

export function isLocked(unlocksAt) {
	return unlocksAt !== null && moment(unlocksAt) > moment();
}
