import React, { Component } from 'react';
import classNames from 'classnames';
import SocialBarMenu from './SocialBarMenu/SocialBarMenu';
import SocialIcon from './SocialIcon/SocialIcon';
import styles from './SocialBar.module.scss';

interface ISocialBarProps {
	className?: string;
	likesCount?: number;
	commentsCount?: number;
	shareLinksCollection: Array<any>;
	inverse?: boolean;
	noSkew?: boolean;
	noBackdrop?: boolean;
	menuClassName?: string;
	menuContainerClassName?: string;
	menuCloseClassName?: string;
}

export default class SocialBar extends Component<ISocialBarProps> {
	state = {
		isOpen: false,
	};

	constructor(props: ISocialBarProps) {
		super(props);
		this.state = { isOpen: false };
	}

	handleSocialBarMoreToggle(event) {
		event.stopPropagation();
		this.setState({ isOpen: !this.state.isOpen });
	}

	render() {
		const { isOpen } = this.state;
		const { noSkew, noBackdrop, menuClassName, menuContainerClassName, menuCloseClassName } = this.props;

		const socialBarClassNames = classNames(styles.socialBar, this.props.className);

		return (
			<div className={socialBarClassNames}>
				{isOpen ? (
					<SocialBarMenu
						noSkew={noSkew}
						noBackdrop={noBackdrop}
						closeHandler={this.handleSocialBarMoreToggle.bind(this)}
						collection={this.props.shareLinksCollection}
						className={menuClassName}
						containerClassName={menuContainerClassName}
						closeClassName={menuCloseClassName}
					/>
				) : null}
				<SocialIcon
					inverse={this.props.inverse}
					onClick={this.handleSocialBarMoreToggle.bind(this)}
					className={styles.socialBarToggle}
				/>
			</div>
		);
	}
}
