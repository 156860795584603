import { POST_ITEMS_TYPES } from '@/utils/constants';
import { formatPostedAtDate, getDateFilled } from '@/utils/post-date';
import React, { FC } from 'react';

function getPostInfoDate(data) {
	let formatedDate;

	switch (POST_ITEMS_TYPES[data.type]) {
		case POST_ITEMS_TYPES['News::Post']:
			formatedDate = formatPostedAtDate(data.postedAt);
			break;
		case POST_ITEMS_TYPES['User::Story']:
		case POST_ITEMS_TYPES['Collection::Item']:
			formatedDate = getDateFilled(data.dateFilled);
			break;
		default:
			return null;
	}

	return formatedDate ? <div>{formatedDate}</div> : null;
}

function getPostInfoLocation(data) {
	return data.location ? <div>{data.location}</div> : null;
}

interface IPostInfoProps {
	className?: string;
	data: object;
}

const PostInfo: FC<IPostInfoProps> = (props) => {
	const { className, data } = props;

	return (
		<div className={className}>
			{getPostInfoDate(data)}
			{getPostInfoLocation(data)}
		</div>
	);
};

export default PostInfo;
